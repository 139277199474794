import React from "react"
import styled, { css } from "styled-components"

const Wrapper = styled.div`
  
    all: unset;
    margin:15px auto; max-width:960px; padding: 0px 15px;
    display:block;
    ${css`
    * {font-family: Arial, Helvetica, sans-serif;     font-size: initial; }
    img {vertical-align: baseline;max-width: 100%}
    header {text-align:center;margin-bottom: 25px;}
    
    nav {display:inline-block;}
    nav a {display:inline-block; margin:0px; padding:10px 20px; text-decoration: none; color:#888;}
    nav a:hover, nav a:active {color:#000; }
    .m-t-lg {margin-top: 30px;}
    .m-b-lg {margin-bottom: 30px;}
    .width {width: 100%;} 
    h1,h2,h3,h4,h5 {font-weight: bold;}
    h1,h2,h3,h4,h5,p,li {color: #888;}
    h1 {color:#333; font-size:28px;}
  
    h1,h2,h3 {margin-top:15px;margin-bottom: 10px;;}
    ul,ol {margin:15px 0px; padding-left:15px; }
    li {margin:30px 0px;}
  
    section:not(:last-child) {margin-bottom:60px; border-bottom:solid 1px #ccc; padding-bottom:60px; }
  
    h5 {font-size:14px; line-height: 1.7;}
    p,li {font-size:14px; line-height: 1.7;}
    `}
  


`; 

const pjdbiHelp = ({ data }) => (
    <Wrapper>
                <header>
                    <nav>
                        <a href="/pjdbi-help/#gettingStarted">Getting started</a>
                        <a href="/pjdbi-help/#basicExploration">Basic Exploration</a>
                        <a href="/pjdbi-help/#askQuestion">Asking Questions</a>
                    </nav>
                </header>
                <section id="gettingStarted" className="width">
                    <h1>Getting Started: What is this tool?</h1>
                    <div className="image-settings width m-t-lg m-b-lg">
                        <img src={require("../images/pjd-full-dark-logo-new.png")} />
                    </div>
                    <h2>What is Pumpjack BI?</h2>
                    <p>Pumpjack BI is a business intelligence tool. It lets you ask questions about your data, and displays answers in formats that make sense, whether that's a bar graph or a detailed table.</p>

                    <p>Your questions can be saved for later, making it easy to come back to them, or you can group questions into great looking dashboards. Pumpjack BI also makes it easy to share questions and dashboards with the rest of your team within the tool so you can stop managing unencrypted spreadsheets.</p>
                    <h2>Finding your way around</h2>
                    <h3>The home page</h3>
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/EmptyHomepage.png")} />
                        <p><small>The home page</small></p>
                    </div>
                    <p>Fresh out of the box, Pumpjack BI will show you a few things on the home page:</p>
                    <ul>
                        <li>Some automatic explorations of your tables that you can look at and save as a dashboard if you like any of them. (Administrators can permanently hide this section by clicking the X that appears on hover.)</li>
                        <li>An area where things you or your teammates create will show up, along with a link to see all the dashboards, questions, and pulses you have.</li>
                        <li>A list of the databases you've connected to Pumpjack BI. (As with x-rays, administrators can permanently hide this section by clicking the X that appears on hover. You can always click on "Browse Data" from the main nav bar to see your databases and tables.)</li>
                    </ul>
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/our-data.png")} />
                        <p><small>Our data</small></p>
                    </div>
                    <p>Once you've created some dashboards, any of them that you pin in the main "Our analytics" collection will show up on the homepage for all of your teammates, so that when they log in to Pumpjack BI they'll know right where to go.</p>
                    <h2>Browse your data</h2>
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/browse-data.png")} />
                        <p><small>Browse data</small></p>
                    </div>

                    <p>You'll see your database listed at the bottom of the homepage. Click on a database to see its contents. You can click on a table to see its rows, or you can also click on the bolt icon to x-ray a table and see an automatic exploration of it, or click on the book icon to go to the data reference view for that table to learn more about it.</p>

                    <h2>Explore your analytics</h2>
                    <p>As you and your team create dashboards and collections, they'll start to show up on the homepage. Click on a collection in the "Our analytics" section to see its contents, or click "browse all items" to see everything you and your team have made. </p>

                    <h2>Ask a question or write a query</h2>
                    <p>Click the <a href="/pjdbi-help/#askQuestion">Ask a question</a> button in the top-right of PumpjackBI to start a new simple exploration of one of your tables, ask a more detailed custom question using the notebook editor, or write a new SQL query if you want to really dig in.</p>

                    <h2>Make a new dashboard or pulse</h2>
                    <p>In Pumpjack BI, dashboards are made up of saved questions that you can arrange and resize as you please. They're a great way to track important metrics and stats that you care about. Pulses are what regularly scheduled reports are called in Pumpjack BI. They can be sent out either via email, Slack, or both.</p>

                    <p>To make a dashboard or pulse, click the plus (+) icon in the top-right of the main navigation bar.</p>

                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/create-menu.png")} />
                        <p><small>Create menu</small></p>
                    </div>

                    <h2>Use search to quickly find things</h2>
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/search-results.png")} />
                        <p><small>Search results</small></p>
                    </div>
                    <p>The search bar that's always present at the top of the screen lets you search through your tables, dashboards, collections, saved questions, metrics, segments, and pulses in an instant. Just type part of the title of the thing you're looking for and hit enter. You can activate the search bar from anywhere by pressing the / key.</p>
                </section>


                <section id="basicExploration" className="width">
                    <h1>Exploring in Pumpjack BI</h1>
                    <div className="image-settings width m-t-lg m-b-lg">
                        <img src={require("../images/pjd-full-dark-logo-new.png")} />
                    </div>
                    <h2>See what your teammates have made</h2>
                    <p>As long as you're not the very first user in your team's Pumpjack BI instance, the easiest way to start exploring your data is by looking at dashboards, charts, and lists that your teammates have already created. The best place to start is by checking out any dashboards that might be pinned on your home page or in the collections you have access to.</p> 
                    <h3>Browse your data</h3>
                    <p>Alternatively, you can dive right in to exploring the data in Pumpjack BI by clicking on one of the databases at the bottom of the home page or clicking the "Browse data" button in the top nav bar, and then selecting a database and clicking on one of its tables to see it. You can also click on the bolt icon on any table to see an automatic exploration of its data. Give it a try!</p>
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/browse-data.png")} />
                        <p><small>Browse data</small></p>
                    </div>
                    <h3>Exploring collections</h3>
                    <p>Collections in Pumpjack BI are a lot like folders. They're where all your team's dashboards and charts are kept. To explore a collection just click on one in the "Our analytics" section of the home page, or click on Browse all items to see everything.</p> 
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/collection-detail.png")} />
                        <p><small>A collection</small></p>
                    </div>
                    <p>If your teammates are cool, they'll have pinned some important dashboards or questions within your collections; if so, those important or useful items will show up nice and large at the top of a collection. Any dashboards that are pinned in the top-level, "Our Analytics" collection will also show up on the Pumpjack BI homepage.</p>

                    <p>Collections also have a list of any other items that are saved within them, as well as a list of other collections that are saved inside the current one.</p>

                    <h2>Exploring dashboards</h2>
                    <p>Dashboards are simply collections of charts and numbers that you want to be able to refer back to regularly.</p> 

                    <p>If you click on a part of a chart, such as a bar in a bar chart, or a dot on a line chart, you'll see a menu with actions you can take to dive deeper into that result, to branch off from it in a different direction, or to x-ray it to see an automatic exploration of the thing you clicked on.</p>
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/drill-through.png")} />
                        <p><small>Drill through</small></p>
                    </div>

                    <p>In this example of pie orders by type over time, clicking on a dot on this line chart gives us the ability to:</p>
                    <ul>
                        <li><b>Zoom in —</b> i.e., see just the banana cream pie orders in June 2017 over time</li>
                        <li><b>View these Orders —</b> which lets us see a list of banana cream pie orders in June 2017</li>
                        <li><b>Break out by a category —</b> this lets us do things like see the banana cream pie orders in June 2017 broken out by the status of the customer (e.g., new or VIP, etc.) or other different aspects of the order. Different charts will have different break out options, such as Location and Time.</li>
                    </ul>
                    <p><b>Note that charts created with SQL don't currently have these action options.</b></p>
                    <p>Other charts as well as table cells will often also allow you to go to a filtered view of that chart or table. For example, You can click on one of the inequality symbols to see that chart where the value of the Subtotal column is less than $100, or where the Purchased-at timestamp is greater than (i.e., after) April 1, 2017.</p>


                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/inequality-filters.png")} />
                        <p><small>Inequality filters</small></p>
                    </div>
                    
                    <p>Lastly, clicking on the ID of an item in a table gives you the option to go to a detail view for that single record. (E.g., you can click on a customer's ID to see the profile view for that one customer.)</p>

                    <h2>Exploring saved questions</h2>
                    <p>In Pumpjack BI parlance, every chart on a dashboard is called a "question." Clicking on the title of a question on a dashboard will take you to a detail view of that question. You'll also end up at this detail view if you use one of the actions mentioned above.</p>

                    <p>When you're looking at the detail view of a question, you can use all the same actions mentioned above. You can also click on the headings of tables to see more options, like summing the values of a column, or filtering based on that column.</p>
                    
                    <p>One of our personal favorite ways to explore is with the Distribution option. This will show you how many rows there are in a given table, grouped by column you clicked on. So if you have a Users table, if you click on an Age column and select Distribution, you'll see a bar chart with the count of users you have in each age bracket.</p>

                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/heading-actions.png")} />
                        <p><small>Inequality filters</small></p>
                    </div>


                    <h2>Next: Asking custom questions</h2>
                    <p>So what do you do if you can't find an existing dashboard or question that's exactly what you're looking for? Let's learn about asking our own new questions</p>
        
                </section>



                <section id="askQuestion" className="width">
                    <h1>Asking Questions</h1>
                    <div className="image-settings width m-t-lg m-b-lg">
                        <img src={require("../images/pjd-full-dark-logo-new.png")} />
                    </div>
                    <h5 className="m-b-lg">REMINDER: All data must be encrypted at rest and in transit. Data must only be shared via the approved 6 Data Vehicles. DO NOT EMAIL SPREADSHEETS CONTAINING PII DATA.</h5>
                    <h2>Asking questions in Pumpjack BI</h2>
                    <p>Pumpjack BI's two core concepts are questions and their corresponding answers. Everything else is based around questions and answers. To ask a question in Pumpjack BI, click the Ask a Question button at the top of the screen.</p>
                    
                    
                    <h3>Ways to start a new question</h3> 
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/new-question.png")} />
                        <p><small>The new question menu</small></p>
                    </div>
                    <h3>Exploring collections</h3>
                    <p>Collections in Pumpjack BI are a lot like folders. They're where all your team's dashboards and charts are kept. To explore a collection just click on one in the "Our analytics" section of the home page, or click on Browse all items to see everything.</p> 
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/collection-detail.png")} />
                        <p><small>A collection</small></p>
                    </div>
                    <p>When you click the Ask a Question button, you'll see that there are three ways to ask a specific question in Pumpjack BI:</p>
                    <ol>
                        <li>The simple question mode, which lets you filter, summarize, and visualize data.</li>
                        <li>The custom question mode, which gives you a powerful notebook-style editor to create more complex questions that require joins, multiple stages of filtering and aggregating, or custom columns.</li>
                        <li>The SQL/native query editor.</li>
                    </ol>

                    <p>This page will cover the simple mode.</p>

                    <h2>Asking a simple question</h2>
                    <p>After you select the Simple Question option, you'll need to pick some data that you have a question about. Just pick one of the databases that's connected to Pumpjack BI, and you'll see a list of all the tables inside of it. Pick the one that you have a question about; it could be a Users table, or maybe it's something like Events, Orders, or Downloads.</p>

                        <p>To ask a question about a table of data, we usually do some <b>filtering</b> and/or <b>summarizing</b>.</p> 

                
                    <h2>Filtering</h2>
                    <p>Filtering just means narrowing things down based on certain criteria. You're probably already familiar with filtering when looking for something online, like when shopping. Maybe you only want to see olive-colored pants, or books where the author's last name is "Steinbeck," or pictures of people wearing olive-colored pants reading John Steinbeck.</p>

                        <p>You can do the same kind of thing with data in Pumpjack BI. Just click the Filter button in the top-right of the screen to open the filter sidebar. You'll see a list of all of the columns in this table, as well as columns from tables that are related to the one you're looking at. Depending on the column you pick, you'll see slightly different options for your filter.</p>
                        
                        <p>Broadly speaking, there are three types of columns, each with their own set of filtering options:</p>
                        <ul>
                            <li><b>Numeric columns</b> let you add filters to only include rows in your table where this number is between two specific values, or is greater or less than a specific value, or is exactly equal to something.</li>
                            <li>With <b>text or category columns</b>, you can specify that you only want to include data where this column is or isn't a specific option, or you can exclude rows that don't have a value for this column.</li>
                            <li><b>Date</b> columns give you a calendar or input box so that you can select specific time ranges, or choose all days before or after a certain date.</li>
                        </ul>

                        <p>One important thing to understand when filtering on a date column is the difference between specific and relative dates:</p>
                        <p>
                            <b>Specific dates</b> are things like November 1, 2010, or June 3 – July 12, 2017; they always refer to the same date(s).
                        </p>
                        <p><b>Relative dates</b> are things like "the past 30 days," or "the current week;" as time passes, the dates these refer to change. Relative dates are a useful way to set up a filter on a question so that it stays up-to-date by showing you for example how many users visited your website in the last 7 days</p>
                        
                        <h3>Filtering by a segment</h3>
                        <p>If your Pumpjack BI administrators have created special named filters for the table you're viewing, they’ll appear at the top of the filter dropdown in purple text with a star next to them. These are called "segments," and they're shortcuts to a combination of filters that are commonly used in your organization. They might be called things like “Active Users,” or “Most Popular Products.”</p>

                            <p>Once you're happy with your filter, click Done, and your data will be updated with your filter applied. If you want to edit your filter, just click the little purple token at the top of the screen. If you click on the X, you'll remove your filter. You can add as many filters as you need.</p>
                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/filter-badge.png")} />
                        <p><small>An active filter</small></p>
                    </div>

                    
                    <h2>Summarizing</h2>
                    <p>When we have a question like "how many people downloaded our app each day this week?" or "what's the average age of customers who visit each of our stores on the weekend?", we're asking for a summary of the data. A summary is usually made up of two parts: one or more numbers we care about (called a "metric" in data-speak), and how we want to see that number grouped or broken out. So in our first example:</p>

                    <ul>
                        <li>the metric is "how many people downloaded our app"</li>
                        <li>we want that metric to be grouped by "each day"</li>
                        <li>and "this week" is a filter</li>
                    </ul>

                    <p>There are two very common ways you'll tend to summarize your data:</p>
                    <ul>
                        <li>Counting the number of rows in your table</li>
                        <li>Getting the sum or average of a numeric column</li> 
                    </ul>

                    <p>And a lot of the time, you'll then group that number by:</p>
                    <ul>
                        <li>Time</li>
                        <li>Place</li> 
                        <li>Some other category like operating system, gender, state, etc.</li>
                    </ul>
                    
                    <p>To do this in Pumpjack BI, click the Summarize button in the top-right of the screen, and the summary sidebar will open up.</p>

                    <div className="image-settings width m-b-lg">
                        <img src={require("../images/summarize-sidebar.png")} />
                        <p><small>The Summarize sidebar</small></p>
                    </div>


                    <h2>Picking metrics</h2>
                    <p>The sidebar has two main parts: the top is where you pick the number ("metric") you want to see, and the part below it is where you pick how to group that number (or how to "break it out").</p>

                        <p>By default the "count of rows" metric will be selected, since it's super common, but you can change it to something else by clicking on it. You can also select more than one metric to view: just click the "add a metric" button. You can remove any selected metric by clicking on the X icon. The different basic metrics are:</p>

                        <ul>
                            <li><b>Count of rows</b>: the total of number of rows in the table, after any filters have been applied. If you're looking at your Orders table and want to know how many orders were placed with a price greater than $40, you’d filter by “Price greater than 40,” and then select Count of rows, because you want Pumpjack BI to count how many orders matched your filter.</li>
                            <li><b>Sum of …</b>: the sum of all the values in a specific column.</li>
                            <li><b>Average of …</b>: the average of all the values in a single column.</li>
                            <li><b>Number of distinct values of…</b>: the number of unique values in all the cells of a single column. This is useful when trying to find out things like how many different types of products were sold last month (not how many were sold in total).</li>
                            <li><b>Cumulative sum of…</b>: This gives you a running total for a specific column. In order for this metric to be useful you'll need to group it by a date column to see it across time.</li>
                            <li><b>Cumulative count of rows</b>: This gives you a running total of the number of rows in the table over time. Just like Cumulative sum of…, you'll need to group this by a date column in order for it to be useful.</li>
                            <li><b>Standard deviation of …</b>: A number which expresses how much the values of a column vary, plus or minus, from the average value of that column.</li>
                            <li><b>Minimum of …</b>: The minimum value present in the selected field.</li>
                            <li><b>Maximum of …</b>: The maximum value present in the selected field.</li>
                        </ul>
                        <p>If your admins have created any named metrics that are specific to your company or organization, they will be in this dropdown under the Common Metrics section. These might be things like your company’s official way of calculating revenue.</p>

                        <h3>Picking a grouping for your metrics</h3>
                        <p>Depending on the grouping column you select, Pumpjack BI will show you what it thinks is the best default visualization or chart for this summary. So if you select a date column, you'll see a line chart like this:</p>
                        <div className="image-settings width m-b-lg">
                            <img src={require("../images/summarize-timeseries.png")} />
                            <p><small>Grouping by a date column</small></p>
                        </div>
                        <p>When you click on a different grouping column than the one you currently have selected, the grouping will switch to use that column instead. But if you want to add an additional grouping, just click the plus (+) icon on the right side of the column. To remove a grouping, click on the X icon.</p>
                        <div className="image-settings width m-b-lg">
                            <img src={require("../images/summarize-timeseries-breakout.png")} />
                            <p><small>Multiple groupings</small></p>
                        </div>

                        <p>Some grouping columns will give you the option of choosing how big or small to make the groupings. So for example, if you've picked a Date column to group by, you can click on the words by month to change the grouping to day, week, hour, quarter, year, etc. If you're grouping by a numeric column, like age, Pumpjack BI will automatically "bin" the results, so you'll see your metric grouped in age brackets, like 0–10, 11–20, 21–30, etc. Just like with dates, you can click on the current binning option to change it to a specific number of bins. It's not currently possible to choose your own ranges for bins, though.</p>

                        <div className="image-settings width m-b-lg">
                            <img src={require("../images/histogram-bins.png")} />
                            <p><small>Multiple groupings</small></p>
                        </div>

                        <p>Once you're done setting your metrics and groupings, click Done to close the Summarize sidebar and see your results in all their glory.</p>

                        <p>If you want to jump ahead and learn about how to change the visualization of your results, by all means, feel free.</p>

                        <h3>Viewing an individual record's details</h3>
                        <p>Click on a record's ID number (or primary key) to see more information about a given user, order, venue, etc. You can see all fields related to that one record and all connected tables that are hidden in the table view for the sake of readability. Press the right or left arrow keys, or click on the arrows to the right or left of the screen to page through the other records in the current list.</p>
                        <div className="image-settings width m-b-lg">
                            <img src={require("../images/record-details.png")} />
                            <p><small>Record details</small></p>
                        </div>  

                        <h3>Downloading Your Results</h3>
                        <p>You can download or export the results of a question by clicking on the Download arrow in the lower right of the screen. Results can be downloaded into .csv, .xlsx, or .json files. The maximum download size is 1 million rows.</p>
                        <div className="image-settings width m-b-lg">
                            <img src={require("../images/download-button.png")} />
                            <p><small>Download Button</small></p>
                        </div>  

                        <h5>REMINDER: All data must be encrypted at rest and in transit. Data must only be shared via the approved 6 Data Vehicles. DO NOT EMAIL SPREADSHEETS CONTAINING PII DATA.</h5>
                </section>
            </Wrapper>
);

export default pjdbiHelp
 
